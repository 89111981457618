/**
 * Created by slava on 24.03.17.
 */

var Keypad = function (game, save) {
    KeypadInterface.call(this);

    this.game = game;
    this.level = game.level;
    this.save = save;
    this.easy = save.easy;

    var words = cleverapps.unique(this.level.content.words);
    this.words = cleverapps.substract(words, save.words);

    if (this.level.quick) {
        this.quick = true; // for quicker admin
    }

    // same mix when no save
    cleverapps.Random.seed(0);
    this.createBlockMixerAndField(save);

    this.indexes = [];

    this.pendingMagnifiers = [];

    if (save.hintBoosters) {
        this.createMagnifiers(save.hintBoosters);
    }
};

Keypad.prototype = Object.create(KeypadInterface.prototype);
Keypad.prototype.constructor = Keypad;

Keypad.prototype.stop = function () {
    this.trigger("tutorialStopped");
};

Keypad.prototype.getAvailableForce = function () {
    if (this.game.counter.isActive()) {
        return;
    }

    if (this.tricky && !cleverapps.forces.isShown(Forces.TRICKY_LEVEL.id)) {
        return Forces.TRICKY_LEVEL;
    }
};

Keypad.prototype.listHints = function () {
    var hints = [];
    this.field.forEach(function (column, col) {
        for (var row = 0; row < column.length; row++) {
            if (column[row] && (column[row] instanceof LetterBlock) && column[row].isHintSet()) {
                hints.push([col, row]);
            }
        }
    });

    return hints;
};

Keypad.prototype.resetHints = function () {
    this.field.forEach(function (column) {
        for (var row = 0; row < column.length; row++) {
            if (column[row] && (column[row] instanceof LetterBlock)) {
                column[row].setHint(false);
            }
        }
    });
};

Keypad.prototype.log = function () {
    return this.field.map(function (arr) {
        return arr.map(function (letter) {
            if (!letter) {
                return " ";
            }

            if (letter instanceof CoinBlock) {
                return letter.type;
            }

            return letter.symbol;
        }).join("");
    });
};

Keypad.prototype.showForce = function (f, force) {
    this.forceJustShown = true;

    if (force === Forces.TRICKY_LEVEL) {
        this.tutorialWord();
    }

    this.trigger("force", f, force);
};

Keypad.prototype.hide = function (f) {
    if (Game.currentGame.outcome === GameBase.OUTCOME_VICTORY) {
        this.trigger("hide", f);
    } else {
        var maxDuration = 0;

        this.field.forEach(function (column) {
            column.forEach(function (block, y) {
                if (block) {
                    var delay = y * 0.1 + Math.random() * 0.1;
                    setTimeout(function () {
                        block.lose();
                    }, delay * 1000);
                    var duration = delay + block.getLoseDuration();
                    if (duration > maxDuration) {
                        maxDuration = duration;
                    }
                }
            }, this);
        }, this);

        setTimeout(f, maxDuration * 1000);
    }
};

Keypad.prototype.getMagnifiers = function () {
    var magnifiers = [];
    for (var x = 0; x < this.field.length; x++) {
        for (var y = 0; y < this.field[x].length; y++) {
            var letterBlock = this.field[x][y];
            if (letterBlock && letterBlock.magnifier) {
                magnifiers.push({
                    x: x,
                    y: y
                });
            }
        }
    }

    return magnifiers;
};

Keypad.prototype.createMagnifiers = function (positions) {
    var added = 0;
    positions.forEach(function (position) {
        if (this.field[position.x] && this.field[position.x][position.y]) {
            this.field[position.x][position.y].addMagnifier();
            added++;
        }
    }, this);

    this.addMagnifiers(positions.length - added, true);
};

Keypad.prototype.addMagnifiers = function (amount, noSave) {
    var letters = [];
    for (var x = 0; x < this.field.length; x++) {
        for (var y = 0; y < this.field[x].length; y++) {
            if (this.field[x][y] instanceof LetterBlock && !this.field[x][y].magnifier) {
                letters.push(this.field[x][y]);
            }
        }
    }

    for (var i = 0; i < amount && letters.length > 0; i++) {
        var index = cleverapps.Random.random(0, letters.length - 1);
        letters[index].addMagnifier();
        letters.splice(index, 1);
    }

    if (!noSave) {
        this.game.storeSave();
    }
};

Keypad.prototype.createField = function (state, hints) {
    return state.map(function (column, col) {
        if (typeof column === "string") {
            column = column.split("");
        }
        return column.map(function (letter, row) {
            if (letter === " ") {
                return undefined;
            }
            if (letter === CoinBlock.TYPE_COIN || letter === CoinBlock.TYPE_BAG) {
                return new CoinBlock(col, row, this, letter);
            }
            var letterBlock = new LetterBlock(col, row, letter.toLowerCase(), this);

            var hasHint = false;
            hints.forEach(function (hint) {
                if (hint[0] === col && hint[1] === row) {
                    hasHint = true;
                }
            });

            if (hasHint) {
                letterBlock.setHint(true);
            }

            return letterBlock;
        }.bind(this));
    }.bind(this));
};

Keypad.prototype.randomEmptyPosition = function () {
    var noCoinsPositions = [];
    for (var col = 0; col < this.field.length; col++) {
        if (this.field[col].length < this.getHeight() && this.field[col].length) {
            var pos = {
                y: this.field[col].length,
                x: col
            };
            if (!(this.field[col][this.field[col].length - 1] instanceof CoinBlock)) {
                noCoinsPositions.push(pos);
            }
        }
    }

    return cleverapps.Random.choose(noCoinsPositions);
};

Keypad.prototype.addRandomBonus = function () {
    var position = this.randomEmptyPosition();

    if (position) {
        var type = CoinBlock.TYPE_COIN;
        var prob = 0.1;
        if (this.game.level.isBonusRound() || this.game.level.isBonus()) {
            prob = 1;
        }

        if (Math.random() < prob) {
            type = CoinBlock.TYPE_BAG;
        }
        this.addBlock(new CoinBlock(position.x, position.y, this, type));
    }
};

Keypad.prototype.addBlock = function (block) {
    this.field[block.x].push(block);

    this.trigger("blockAdded", block);

    block.showUp();
};

Keypad.prototype.removeBlock = function (x, y) {
    this.field[x].splice(y, 1);
};

Keypad.prototype.getState = function () {
    return {
        width: this.getWidth(),
        height: this.getHeight(),
        state: this.log(),
        hints: this.listHints()
    };
};

Keypad.prototype.createBlockMixerAndField = function (save) {
    // console.log('createBlockMixerAndField', save)
    var reverseChance = 0;
    if (this.game.level.getHumanReadableNumber() > 15) {
        reverseChance = 0.5;
    } else if (this.game.level.getHumanReadableNumber() > 5) {
        reverseChance = 0.2;
    }

    if (this.game.level.isBonusRound() || this.game.level.isBonus()) {
        reverseChance = 0;
    }

    var isSaveValid = save.keypad && BlockMixer.isSameWords(save.keypad.state, this.words, {
        skip: " " + CoinBlock.TYPE_COIN + CoinBlock.TYPE_BAG
    });

    this.tricky = this.level.isTricky() && (this.game.emptySave || !isSaveValid);

    var mixer = this.mixer = new BlockMixer({
        reverse: reverseChance,
        quick: this.quick,
        easy: this.easy,
        fillEmpty: this.tricky || cleverapps.flags.fillEmpty
    });

    if (isSaveValid) {
        mixer.setSize(save.keypad);

        this.field = this.createField(save.keypad.state, save.keypad.hints || []);

        return;
    }

    this.guessSize();

    this.field = this.createField(mixer.getState(), []);
};

Keypad.prototype.guessSize = function () {
    if (Keypad.SIZES.length === 1) {
        // for ads
        this.mixer.setSize(Keypad.SIZES[0]);
        this.mixer.mix(this.words);
        return;
    }

    var totalLetters = 0;
    var maxLength = 0;
    this.words.forEach(function (word) {
        totalLetters += word.length;
        if (word.length > maxLength) {
            maxLength = word.length;
        }
    });

    // console.log("Guess size: ", totalLetters, maxLength)

    for (var i = 0; i < Keypad.SIZES.length; i++) {
        var size = Keypad.SIZES[i];

        var space = size.width * size.height;
        // console.log('Try size: ', size, space)

        if (space * 0.85 < totalLetters || size.width + 1 < maxLength) {
            continue;
        }

        this.mixer.setSize(size);

        this.mixer.mix(this.words);

        if (this.mixer.state) {
            // console.log("Found size:", size)
            break;
        }
    }
};

Keypad.SIZES = [
    { width: 6, height: 8 },
    { width: 8, height: 10 },
    { width: 9, height: 11 },
    { width: 10, height: 13 },
    { width: 11, height: 14 },
    { width: 12, height: 15 },
    { width: 14, height: 18 },
    { width: 16, height: 20 },
    { width: 18, height: 23 },
    { width: 20, height: 26 }
];

Keypad.prototype.getWidth = function () {
    return this.mixer.width;
};

Keypad.prototype.getHeight = function () {
    return this.mixer.height;
};

Keypad.prototype.moveExists = function () {
    return this.words.some(function (word) {
        return this.findWordIndexes(word);
    }, this);
};

Keypad.prototype._shuffle = function (silent) {
    var oldState = this.field.map(function (column) {
        return column.reduce(function (acc, block) {
            return acc + (block && block instanceof LetterBlock && block.symbol || "");
        }, "");
    }).filter(function (column) {
        return column.length > 0;
    });

    for (var tries = 0; tries < 10; tries++) {
        var state = this.mixer.mix(this.words);
        if (state !== undefined && !BlockMixer.areStatesSame(state, oldState)) {
            break;
        }
    }

    if (this.mixer.state === undefined) {
        this.guessSize();
        if (!silent) {
            this.trigger("resize");
        }
    }

    var ch;
    var letterBlocks = {};
    var coins = [];
    for (var x = 0; x < this.field.length; x++) {
        for (var y = 0; y < this.field[x].length; y++) {
            if (this.field[x][y] instanceof LetterBlock) {
                ch = this.field[x][y].symbol;
                if (!letterBlocks[ch]) {
                    letterBlocks[ch] = [];
                }
                letterBlocks[ch].push(this.field[x][y]);
            } else {
                coins.push(this.field[x][y]);
            }
        }
    }

    for (ch in letterBlocks) {
        cleverapps.Random.shuffle(letterBlocks[ch]);
    }

    this.field = this.mixer.state.map(function (column, col) {
        if (typeof column === "string") {
            column = column.split("");
        }
        return column.map(function (ch, row) {
            var blocks = letterBlocks[ch];

            if (!blocks) {
                cleverapps.throwAsync({
                    group: "ShuffleLetters",
                    message: "No letter '" + ch + "' on field, available letter: " + Object.keys(letterBlocks).join(", ") + " " + JSON.stringify(new Error().stack)
                });
                return;
            }

            var block = letterBlocks[ch].pop();
            block.move(col, row, silent);
            return block;
        }).filter(Boolean);
    });

    for (ch in letterBlocks) {
        letterBlocks[ch].forEach(function (letterBlock) {
            if (this.easy) {
                letterBlock.remove(true);
                return;
            }

            var position = this.randomEmptyPosition();
            if (position) {
                letterBlock.move(position.x, position.y);
                this.field[position.x][position.y] = letterBlock;
            } else {
                letterBlock.remove();
            }
        }, this);
    }

    coins.forEach(function (coin) {
        var position = this.randomEmptyPosition();
        if (position) {
            coin.move(position.x, position.y, silent);
            this.field[coin.x].push(coin);
        } else {
            coin.collect();
        }
    }, this);
};

Keypad.prototype.shuffle = function () {
    this.resetHints();
    this._shuffle();

    this.trigger("shuffle");

    this.game.counter.setTimeout(function () {}, 1500);
};

Keypad.prototype.amountKeys = function () {
    return this.game.board.words.length;
};

Keypad.prototype.magnifiersShowUp = function (silent) {
    var boosterIndex = 0;
    for (var x = 0; x < this.field.length; x++) {
        this.field[x].forEach(function (letterBlock) {
            if (letterBlock && letterBlock.magnifier) {
                var delay = silent ? 0 : boosterIndex * 100;
                this.game.counter.setTimeout(function () {
                    letterBlock.magnifier.showUp(silent);
                }, delay);
                boosterIndex++;
            }
        }.bind(this));
    }
};

Keypad.prototype.showUp = function (f, silent) {
    var callback = function () {
        this.magnifiersShowUp(silent);
        this.trigger("showUp");
        f();
    }.bind(this);

    if (silent) {
        this.field.forEach(function (column) {
            column.forEach(function (block) {
                if (block) {
                    block.showUp(true);
                }
            }, this);
        }, this);

        callback();
        return;
    }

    var maxDuration = 0;
    var minFirstRowDuration = Number.MAX_VALUE;
    this.field.forEach(function (column) {
        column.forEach(function (block, y) {
            if (block) {
                var delay = y * 0.1 + Math.random() * 0.1;
                setTimeout(function () {
                    block.showUp();
                }, delay * 1000);
                var duration = delay + block.getShowUpDuration();
                if (duration > maxDuration) {
                    maxDuration = duration;
                }
                if (y === 0 && duration < minFirstRowDuration) {
                    minFirstRowDuration = duration;
                }
            }
        }, this);
    }, this);

    setTimeout(function () {
        cleverapps.audio.playSound(bundles.game.urls.letter_drop_effect_0);
    }, minFirstRowDuration * 1000);

    setTimeout(callback, maxDuration * 1000 + 100);
};

Keypad.prototype.tutorialWord = function () {
    this.isTutorialRunning = true;
    this.showTutorialStep();
};

Keypad.prototype.showTutorialStep = function () {
    if (!this.isTutorialRunning) {
        return;
    }

    for (var i = 0; i < this.words.length; i++) {
        var indexes = this.findWordIndexes(this.words[i]);
        if (indexes) {
            this.trigger("tutorialStarted", indexes);
            this.isTutorialRunning = false;
            break;
        }
    }
};

Keypad.prototype.status = function (status, letters) {
    var direction = (letters.length < 2) ? undefined : {
        x: letters[1].x - letters[0].x,
        y: letters[1].y - letters[0].y
    };

    this.reset();

    letters.forEach(function (letter) {
        letter.status(status, direction);
    });

    this.trigger("status", status, letters);
};

Keypad.prototype.fall = function () {
    var field = this.field;
    var maxDuration = 0;
    for (var x = 0; x < this.field.length; x++) {
        var gaps = [];
        var filled = 0;
        for (var y = 0; y < this.field[x].length; y++) {
            if (!field[x][y]) {
                gaps.push({
                    y: y,
                    x: x
                });
            } else if (gaps.length > filled) {
                var upperGap = gaps[filled];
                var duration = field[x][y].move(upperGap.x, upperGap.y, {
                    updateKeypad: true
                });
                if (duration > maxDuration) {
                    maxDuration = duration;
                }
                filled++;
                gaps.push({
                    x: x,
                    y: y
                });
            }
        }

        var toRemove = gaps.length - filled;
        if (toRemove) {
            this.field[x].splice(-toRemove, toRemove);
        }
    }

    if (maxDuration > 0) {
        this.game.counter.setTimeout(function () {}, maxDuration * 1000 + 50);
    }
};

Keypad.prototype.slide = function () {
    var field = this.field;
    var i;

    var reverse = false;
    for (i = 0; i < field.length / 2; i++) {
        if (field[i].length > 0) {
            reverse = true;
            break;
        }
        if (field[field.length - i - 1].length > 0) {
            break;
        }
    }

    var maxDuration = 0;
    var gaps = [];
    var filled = 0;
    var firstNotEmptyColFound = false;
    for (i = 0; i < field.length; i++) {
        var x = i;
        if (reverse) {
            x = field.length - i - 1;
        }
        if (field[x].length > 0) {
            if (!firstNotEmptyColFound) {
                firstNotEmptyColFound = true;
            } else if (gaps.length > filled) {
                var firstEmptyGap = gaps[filled];
                for (var y = 0; y < field[x].length; y++) {
                    var letter = field[x][y];
                    var duration = letter.move(firstEmptyGap, letter.y, {
                        updateKeypad: true
                    });
                    if (duration > maxDuration) {
                        maxDuration = duration;
                    }
                }
                filled++;
                gaps.push(x);

                field[x] = [];
            }
        } else if (firstNotEmptyColFound) {
            gaps.push(x);
        }
    }

    if (maxDuration > 0) {
        this.game.counter.setTimeout(function () {
        }, maxDuration * 1000 + 50);
    }
};

Keypad.prototype.processMagnifiers = function () {
    var magnifier = this.pendingMagnifiers.shift();
    if (magnifier) {
        magnifier.use();
    }
};

Keypad.prototype.collectLeftMagnifiers = function () {
    if (this.words.length === 0) {
        for (var x = 0; x < this.field.length; x++) {
            for (var y = 0; y < this.field[x].length; y++) {
                if (this.field[x][y] && this.field[x][y].magnifier) {
                    this.pendingMagnifiers.push(this.field[x][y].magnifier);
                    this.field[x][y].magnifier.beforeUse();
                    delete this.field[x][y].magnifier;
                }
            }
        }
    }
};

Keypad.prototype.markDiscovered = function (word, letters) {
    letters.forEach(function (letter) {
        letter.removed = true;

        if (letter.magnifier) {
            this.pendingMagnifiers.push(letter.magnifier);
            letter.magnifier.beforeUse();
        }

        this.field[letter.x][letter.y] = undefined;
    }.bind(this));

    this.words = cleverapps.substract(this.words, [word]);

    if (this.pendingMagnifiers[0]) {
        this.pendingMagnifiers[0].move();
    }
};

Keypad.prototype.reset = function () {
    this.trigger("reset");
    while (this.indexes.length > 0) {
        this.pop();
    }
};

Keypad.prototype.stopTouch = function () {
    this.indexes.forEach(function (index) {
        if (this.field[index.x][index.y]) {
            this.field[index.x][index.y].unhighlight();
        }
    }.bind(this));

    this.reset();

    this.trigger("touchStopped");
};

Keypad.prototype.combineWord = function (letters) {
    return letters.map(function (letter) {
        return letter.symbol;
    }).join("");
};

Keypad.prototype.updateSelection = function (letter, move) {
    var indexes = [letter];
    var row = letter.y;
    var col = letter.x;
    for (var i = 1; i < move.length; i++) {
        row += move.direction.y;
        col += move.direction.x;

        if (!this.field[col] || !this.field[col][row] || !(this.field[col][row] instanceof LetterBlock)) {
            break;
        }
        indexes.push(this.field[col][row]);
    }

    var same;
    for (same = 0; same < this.indexes.length && same < indexes.length; same++) {
        if (this.indexes[same].x !== indexes[same].x || this.indexes[same].y !== indexes[same].y) {
            break;
        }
    }

    for (i = 0; this.indexes.length > same; i++) {
        this.pop();
    }

    for (var j = this.indexes.length; j < indexes.length; j++) {
        this.push(indexes[j]);
    }
};

Keypad.prototype.touchBegan = function (letter) {
    if (cleverapps.forces.isRunningForce()) {
        cleverapps.forces.closeRunningForce();
    }

    this.trigger("touchBegan");

    if (this.game.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }

    if (this.game.counter.isActive()) {
        return false;
    }

    this.updateSelection(letter, {
        direction: BlockMixer.DIRECTIONS[0],
        length: 1
    });

    return true;
};

Keypad.prototype.touchMoved = function (letter, move) {
    if (this.game.counter.isActive()) {
        return;
    }

    this.updateSelection(letter, move);
};

Keypad.prototype.touchEnded = function () {
    if (this.game.counter.isActive()) {
        return;
    }

    this.trigger("attempt", this.indexes.slice());

    if (!this.game.counter.isActive() && levels.tutorial.isRunning()) {
        this.tutorialWord();
        this.showTutorialStep();
    }
};

Keypad.prototype.push = function (letter) {
    this.indexes.push(letter);

    letter.highlight();

    this.trigger("push", letter);
};

Keypad.prototype.pop = function () {
    var lastLetter = this.indexes.pop();

    lastLetter.unhighlight();

    this.trigger("pop");
};

Keypad.prototype.findWordIndexes = function (word) {
    var res = BlockMixer.contains(this.field, word, {
        details: true
    });

    if (!res) {
        return undefined;
    }

    return res.indexes.map(function (index) {
        return this.field[index.x][index.y];
    }, this);
};

Keypad.prototype.isOpened = function (word) {
    var indexes = this.findWordIndexes(word);
    return indexes && indexes.length === indexes.filter(function (letter) {
        return letter.isHintSet();
    }).length;
};

Keypad.prototype.findOpenedWord = function () {
    for (var index = 0; index < this.words.length; index++) {
        var word = this.words[index];
        if (this.isOpened(word)) {
            return this.findWordIndexes(word);
        }
    }
};

Keypad.prototype.selectHintPosition = function () {
    var positions = [];
    var board = this.game.board;
    this.words.forEach(function (word) {
        var letters = this.findWordIndexes(word);
        if (letters) {
            letters.forEach(function (letter, index) {
                if (!letter.isHintSet()) {
                    var boardIndex = board.listWordIndexes(word)[index];
                    if (board.isLetterUnguessed(boardIndex[0], boardIndex[1])) {
                        positions.push({
                            word: word,
                            index: index,
                            letter: letter
                        });
                    }
                }
            });
        }
    }.bind(this));

    return cleverapps.Random.mathChoose(positions);
};

if (cleverapps.config.debugMode) {
    Keypad.prototype.showWordDebug = function () {
        var words = cleverapps.Random.shuffle(this.words);
        for (var i = 0; i < words.length; i++) {
            var letters = this.findWordIndexes(words[i]);
            if (letters) {
                letters.forEach(function (letter) {
                    letter.highlight();
                    setTimeout(function () {
                        letter.unhighlight();
                    }, 500);
                });
                break;
            }
        }
    };

    Keypad.prototype.showExtraWordDebug = function () {
        var words = Object.keys(this.game.extra.wordsSet);
        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            if (this.words.indexOf(word) === -1 && !this.game.extra.foundWordsSet[word]) {
                var letters = this.findWordIndexes(word);
                if (letters) {
                    letters.forEach(function (letter) {
                        letter.highlight();
                        setTimeout(function () {
                            letter.unhighlight();
                        }, 500);
                    });
                    break;
                }
            }
        }
    };
}